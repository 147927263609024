<template>
  <v-card>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card-title class="grid-close">
      <span class="headline">Agregar etiqueta</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mt-6">
        <v-col cols="12" sm="6" md="6" class="v-flex-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <img
                @click="showFileChooser"
                class="image"
                v-on="on"
                width="250px"
                height="250px"
                style="border-radius: 10px"
                :src="defaultImage"
                alt=""
              />
            </template>
            <span>Clic para seleccionar una imagen.</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-label>Nombre de la etiqueta</v-label>
              <v-text-field
                style="border-radius: 10px"
                type="text"
                filled
                rounded
                placeholder="Ingrese el nombre de la etiqueta"
                v-model="tag.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-switch
                v-model="tag.categoryVisible"
                class="ml-1"
                label="Es filtro"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row class="pa-0 ma-0">
        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="start">
            <v-switch v-model="tag.active" label="Etiqueta activa"></v-switch>
          </v-row>
        </v-col>

        <v-col cols="6" xs="6" sm="6" md="6">
          <v-row align="center" justify="end">
            <v-btn class="save-btn mt-4" @click="save" color="primary"
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>

    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="imageChanged"
    />
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "add-tag",
  props: ["country", "position", "selectedItem"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      defaultImage: require("@/assets/templateImage.svg"),
      file: null,
      loading: true,
      tag: {
        active: true,
        categoryVisible: true,
      },
    };
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    imageChanged(e) {
      if (!e.target.files[0]) return;

      this.file = e.target.files[0];

      if (this.file && this.file.type.indexOf("image/") === -1) {
       console.log("tipo de archivo no permitido");
        this.file = null;
      }

      var reader = new FileReader();
      reader.onload = (e) => {
        this.defaultImage = e.target.result;
      };

      if (this.file) {
        reader.readAsDataURL(this.file);
      }
    },

    save() {
      if (this.tag.name) {
        this.loading = true;
        let data = {
          name: this.tag.name,
          categoryVisible: this.tag.categoryVisible
            ? this.tag.categoryVisible
            : false,
          active: this.tag.active ? this.tag.active : false,
          deleted: false,
        };

        let ref = db.collection(`countries/${this.country}/tagsOVive`);

        if (this.selectedItem) {
          data.modifiedAt = new Date();
          data.modifiedBy = this.$store.state.user[".key"];
          ref = ref.doc(this.selectedItem[".key"]).update(data);
        } else {
          data.createdAt = new Date();
          data.createdBy = this.$store.state.user[".key"];
          data.position = this.position;

          ref = ref.add(data);
        }
        ref
          .then(async (response) => {
            if (this.file) {
             // console.debug(this.file);
              await this.uploadImage(
                this.selectedItem ? this.selectedItem[".key"] : response.id
              );
            }
            this.loading = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";

            //console.log(ee);
          });
      } else {
        this.snackbarText = "Debe ingresar el nombre de la etiqueta";
        this.snackbar = true;
      }
    },

    uploadImage(tagId) {
      return new Promise((resolve, reject) => {
        fb.app()
          .storage(`${process.env.VUE_APP_EL_OCHO_BK}-tags`)
          .ref()
          .child(
            `${this.country}/oVive/${tagId}/newFile.${this.file.name
              .split(".")
              .pop()}`
          )
          .put(this.file)
          .then((res) => {
           // console.debug(res);
            resolve("success");
          })
          .catch((err) => {
           // console.debug(err);
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error al subir la imágen, actualice desde la página de etiquetas";
            this.snackbar = true;
          });
      });
    },
  },
  mounted() {
    this.loading = false;

    if (this.selectedItem) {
      this.tag = Object.assign({}, this.selectedItem);
      this.defaultImage = this.tag.image
        ? this.tag.image.original
        : this.defaultImage;
    }
  },
};
</script>

<style scoped>
.image {
  cursor: pointer;
  object-fit: contain;

  /* border: 0.5px solid #8080804a; */
}
.v-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload {
  display: none;
}
</style>
