var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('headerLayaut',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"pl-2",staticStyle:{"border-radius":"10px"},attrs:{"items":_vm.countries,"placeholder":"Escoge el pais","filled":"","max-width":"400px","item-text":"name","item-value":".key","hide-details":"","rounded":""},on:{"change":_vm.getTags},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text mr-5",staticStyle:{"border-radius":"10px"},attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.selectedCountry},on:{"click":function($event){_vm.sortDialog = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-bars")]),_vm._v(" Ordenar ")],1),_c('v-btn',{staticClass:"ma-2 white--text",staticStyle:{"border-radius":"10px"},attrs:{"color":"primary","disabled":!_vm.selectedCountry,"loading":_vm.loading},on:{"click":function($event){return _vm.addTag()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Crear nueva etiqueta ")],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tags,"items-per-page":15,"search":_vm.$store.state.search,"loading":_vm.loading,"item-key":".key","sort-by":['position'],"sort-desc":[false, true],"single-expand":true,"fixed-header":"","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:`item.categoryVisible`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                  { categoryVisible: item.categoryVisible },
                  item['.key'],
                  item
                )}},model:{value:(item.categoryVisible),callback:function ($$v) {_vm.$set(item, "categoryVisible", $$v)},expression:"item.categoryVisible"}})],1)]}},{key:`item.image`,fn:function({ item }){return [(item.image)?_c('v-avatar',[_c('img',{attrs:{"src":item.image.original,"alt":"John"}})]):_vm._e()]}},{key:`item.active`,fn:function({ item }){return [_c('v-switch',{attrs:{"loading":_vm.loading},on:{"change":function($event){return _vm.switchControlChanged(
                { active: item.active },
                item['.key'],
                item
              )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.editTag(item)}}},[_vm._v("Editar")]),_c('v-btn',{staticClass:"ml-5 white--text",attrs:{"loading":_vm.loading,"small":"","color":"red darken-4"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_vm._v("Eliminar")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":""},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('add',{attrs:{"position":_vm.tags.length + 1,"country":_vm.selectedCountry,"selectedItem":_vm.selectedItem},on:{"cancel":function($event){_vm.addDialog = false;
        _vm.selectedItem = null;},"success":_vm.tagAdded}})],1):_vm._e(),(_vm.sortDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.sortDialog),callback:function ($$v) {_vm.sortDialog=$$v},expression:"sortDialog"}},[_c('sort',{attrs:{"country":_vm.selectedCountry,"tags":_vm.tags},on:{"success":_vm.handleSort,"cancel":function($event){_vm.sortDialog = false}}})],1):_vm._e(),(_vm.deleteDialog)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar acción "),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_vm._v(" ¿Está seguro que desea eliminar "+_vm._s(_vm.selectedItem.name)+"? ")]),_c('v-card-actions',{staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"border-radius":"50px"},attrs:{"color":"red darken-4","width":"90%","dark":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }